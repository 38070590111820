<template>
  <PageMain class="page-home">
    <div class="container">
      <SliderBanners
        v-if="data?.banners?.totalCount"
        :data="data?.banners?.items"
      />

      <div class="page-home__catalog-button">
        <ButtonCatalog size="xl" />
      </div>

      <ServiceInfo />

      <SliderTiles
        v-if="data?.sliderBestsellers?.totalCount"
        :title="$t('sliders.bestsellers')"
        :list="data?.sliderBestsellers?.items"
        variant="products"
      >
        <template #slide="{ item }">
          <ProductTile
            :data="item"
            @viewed="onProductViewed($event, $t('sliders.bestsellers'))"
            @selected="onProductSelected($event, $t('sliders.bestsellers'))"
          />
        </template>
      </SliderTiles>

      <SliderTiles
        v-if="data?.sliderNew?.totalCount"
        :title="$t('sliders.newReleases')"
        :list="data?.sliderNew?.items"
        variant="products"
      >
        <template #slide="{ item }">
          <ProductTile
            :data="item"
            @viewed="onProductViewed($event, $t('sliders.newReleases'))"
            @selected="onProductSelected($event, $t('sliders.newReleases'))"
          />
        </template>
      </SliderTiles>

      <SliderTiles
        v-if="data?.sliderReading?.totalCount"
        :title="$t('sliders.readInBookstores')"
        :list="data?.sliderReading.items"
        variant="products"
      >
        <template #slide="{ item }">
          <ProductTile
            :data="item"
            @viewed="onProductViewed($event, $t('sliders.readInBookstores'))"
            @selected="onProductSelected($event, $t('sliders.readInBookstores'))"
          />
        </template>
      </SliderTiles>

      <SliderTiles
        v-if="data?.events?.totalCount"
        :title="$t('sliders.newsAndEvents')"
        :list="data?.events.list"
        variant="promo"
        routeMore="/events"
      >
        <template #slide="{ item }">
          <ArticleTile :data="item" />
        </template>
      </SliderTiles>
    </div>

    <SeoContent
      v-if="data?.homeSeo"
      :content="data?.homeSeo"
    />
  </PageMain>
</template>

<script lang="ts" setup>
import type { IAnalyticsProduct } from '@types';
import { PageMain } from '@entities/PageMain';
import { SliderBanners } from '@widgets/SliderBanners';
import { ServiceInfo } from '@entities/ServiceInfo';
import { SeoContent } from '@features/SeoContent';
import { ProductTile } from '@features/ProductTile';
import { ArticleTile } from '@entities/ArticleTile';
import { ButtonCatalog } from '@entities/ButtonCatalog';
import { SliderTiles } from '@widgets/SliderTiles';

interface IHomeData {
  page: {
    metaTitle: string;
    metaDescription: string;
  };
  banners: {
    totalCount: number;
    items: any[];
  };
  bookshops: any[];
  sliderBestsellers: {
    totalCount: number;
    items: any[];
  };
  sliderNew: {
    totalCount: number;
    items: any[];
  };
  sliderReading: {
    totalCount: number;
    items: any[];
  };
  events: {
    totalCount: number;
    list: any[];
  };
  homeSeo: string | null;
}

const { addSeoMeta, addSeoStore, addSeoSearch } = useSeo();

const { trackViewedProductTile, trackSelectedProductTile } = useAnalytics();

const { locale, t: $t } = useI18n();

const { store } = storeToRefs(useMainStore());

const { data } = await useAsyncData(
  () =>
    fetchApiGet('/home', {
      data: {
        urlKey: store.value?.homeUrlKey,
      },
    }) as Promise<IHomeData>
);

const onProductViewed = (product: IAnalyticsProduct, itemListName: string) => {
  trackViewedProductTile({ itemListName, product });
};

const onProductSelected = (product: IAnalyticsProduct, itemListName: string) => {
  trackSelectedProductTile({ itemListName, product });
};

const metaTitle = computed(() => data.value?.page?.metaTitle);
const metaDescription = computed(() => data.value?.page?.metaDescription);
const workingHours = parseWorkingHours(store.value?.storeHours, locale.value);

addSeoMeta({
  title: metaTitle.value,
  description: metaDescription.value,
});

addSeoStore({
  name: store.value?.metaTitle,
  image: data.value?.sliderBestsellers?.items?.[0]?.image?.xs2,
  currenciesAccepted: [store.value?.currencyCode].join(', '),
  phone: `+38${store.value?.storePhone?.replaceAll(' ', '')}`,
  addresses: data.value?.bookshops,
  daysOpen: workingHours?.daysOpen,
  hoursOpen: workingHours?.hoursOpen,
  hoursClose: workingHours?.hoursClose,
});

addSeoSearch();
</script>

<style lang="scss" src="./styles.scss" />
