<template>
  <div
    ref="slider"
    class="slider-banners swiper"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(banner, idx) in data"
        :key="idx"
        class="swiper-slide"
      >
        <a
          :href="banner.url"
          :title="banner.title"
          :aria-label="banner.alt"
          class="slider-banners__item"
          :target="banner.newtab ? '_blank' : '_self'"
        >
          <picture>
            <source
              :srcset="`${banner.image.sm} 1x`"
              media="(max-width: 767px)"
            />

            <source
              :srcset="`${banner.image.md} 1x`"
              media="(min-width: 768px)"
            />

            <img
              :src="banner.image.md"
              class="slider-banners__img"
              :alt="banner.alt"
              :title="banner.title"
              :loading="idx > 0 ? 'lazy' : 'defer'"
              width="498"
              height="580"
            />
          </picture>
        </a>
      </div>
    </div>
    <div
      ref="sliderPagination"
      class="swiper-pagination slider-banners__pagination"
    />

    <div class="slider-banners__nav">
      <div
        ref="sliderPrev"
        class="swiper-arrow-circle slider-banners__arrow"
      >
        <Icon name="arrow-left" />
      </div>
      <div
        ref="sliderNext"
        class="swiper-arrow-circle slider-banners__arrow"
      >
        <Icon name="arrow-right" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { CSSSelector } from 'swiper/types';
import { Swiper } from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Icon } from '@shared/Icon';

const swiperInstance = ref<Swiper | null>(null);
const slider = ref<HTMLElement | CSSSelector | ''>('');
const sliderPagination = ref<CSSSelector | HTMLElement | null>(null);
const sliderPrev = ref<CSSSelector | HTMLElement | null>(null);
const sliderNext = ref<CSSSelector | HTMLElement | null>(null);

interface Banners {
  image: {
    sm: string;
    sm2: string;
    md: string;
    md2: string;
  };
  title: string;
  url: string;
  alt: string;
  newtab: boolean;
}

withDefaults(
  defineProps<{
    data: Banners[];
  }>(),
  {
    data: undefined,
  }
);

onMounted(() => {
  swiperInstance.value = new Swiper(slider.value, {
    modules: [Navigation, Pagination, Autoplay],
    speed: 500,
    slidesPerView: 1,
    spaceBetween: 20,
    loop: true,
    autoplay: {
      delay: 5000,
      pauseOnMouseEnter: true,
    },
    breakpoints: {
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      },
    },
    pagination: {
      el: sliderPagination.value,
      clickable: true,
    },
    navigation: {
      nextEl: sliderNext.value,
      prevEl: sliderPrev.value,
    },
  });
});

onBeforeUnmount(() => {
  if (swiperInstance.value) {
    swiperInstance.value.destroy();
  }
});
</script>

<style lang="scss" src="./SliderBanners.scss" />
