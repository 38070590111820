interface IWorkingHours {
  daysOpen: Array<string>;
  hoursOpen: string;
  hoursClose: string;
}

export function parseWorkingHours(text?: string, locale: string = 'ua'): IWorkingHours | null {
  if (!text) {
    return null;
  }

  const fullDayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const shortDayNames = {
    ua: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Нд'],
    en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  } as any;

  const regex =
    locale === 'ua'
      ? /([А-Я][а-я])-([А-Я][а-я])\s+(\d{2}:\d{2})-(\d{2}:\d{2})/
      : /([A-Z][a-z]{2})-([A-Z][a-z]{2})\s+(\d{2}:\d{2})-(\d{2}:\d{2})/;

  const match = text.match(regex);

  if (!match) {
    return null;
  }

  const [, startDay, endDay, openTime, closeTime] = match;

  function getFullDayRange(start: string, end: string): string[] {
    const startIndex = shortDayNames[locale].indexOf(start);
    const endIndex = shortDayNames[locale].indexOf(end);

    if (startIndex === -1 || endIndex === -1) {
      return [];
    }

    if (endIndex >= startIndex) {
      return fullDayNames.slice(startIndex, endIndex + 1);
    } else {
      return [...fullDayNames.slice(startIndex), ...fullDayNames.slice(0, endIndex + 1)];
    }
  }

  const daysOpen = getFullDayRange(startDay, endDay);

  return {
    daysOpen,
    hoursOpen: openTime,
    hoursClose: closeTime,
  };
}
