<template>
  <div class="service-info">
    <div
      v-for="(item, key) in items"
      :key
      class="service-info__item"
    >
      <Icon :name="item.icon" />
      <div>{{ item.title }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Icon } from '@shared/Icon';

interface ServiceItem {
  icon: string;
  title: string;
}

const { t: $t } = useI18n();

const items = ref<ServiceItem[]>([
  {
    icon: 'gift',
    title: $t('serviceInfo.freeDelivery'),
  },
  {
    icon: 'entente',
    title: $t('serviceInfo.publishersPrice'),
  },
  {
    icon: 'delivery',
    title: $t('serviceInfo.fastDelivery'),
  },
  {
    icon: 'bestsellers',
    title: $t('serviceInfo.bestsellers'),
  },
]);
</script>

<style lang="scss" src="./ServiceInfo.scss" />
